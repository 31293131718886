<script>
import { Modal } from "bootstrap";
import Layout from "../../layouts/main.vue";
import { getExercises,removeExercise,addAliases } from "@/services/admin.service";
import PageHeader from "@/components/page-header";
import appConfig from "../../../app.config";
import { useAuth0 } from "@auth0/auth0-vue";

export default {
    exerciseobj: "",
    setup() {
      const { getAccessTokenSilently } = useAuth0();
      return{
        getAccessTokenSilently
      }
    },
  page: {
    title: "Starter",
    meta: [{ name: "description", content: appConfig.description }],
  },
  methods: {
    deleteModal(item, index) {
        this.exercise = item;
        this.removeid = index;
        this.myRemoveModal = new Modal(document.getElementById('deleteModal'), {})
        this.myRemoveModal.show()
    },
    aliasModal(item, index) {
        this.exercise = item;
        this.exerciseid = index;
        this.myAliasModal = new Modal(document.getElementById('aliasModal'), {})
        this.myAliasModal.show()
    },
    async getData(){
          //var result = false;
          const { getAccessTokenSilently } = useAuth0();
          const accessToken = await getAccessTokenSilently();
          const { data, error } = await getExercises(accessToken);
          if (data) {
            this.exercises = data;
          }
          if (error) {
            this.exercises = [];
          }
      },
      async removeExercise(exercise,index){
        this.exerciseid = this.$refs.exerciseid.value
        console.log("removing: " + exercise + "at index: " + index);
        const deletedata = JSON.stringify({
            exerciseid: this.exerciseid,
        })
        console.log("Removing: " + deletedata);
        const accessToken = await this.getAccessTokenSilently();
        const { data, error } = await removeExercise(accessToken,deletedata);
        if (data) {
            console.log(data);
            this.myRemoveModal.hide();
            this.exercises.splice(index,1);
            
        }
        if (error) {
            console.log(error);
        }
      },
      async addAliases(){
        this.exerciseid = this.$refs.exerciseid.value
        this.aliases = this.$refs.aliases.value
        this.aliasarray = this.aliases.split(',');
        console.log("Aliases: " + this.aliasarray);
        const exerciseobj = JSON.stringify({
            exerciseid: this.exerciseid,
            aliases: this.aliasarray
        })
        const accessToken = await this.getAccessTokenSilently();
        const { data, error } = await addAliases(accessToken,exerciseobj);
        if (data) {
            console.log(data);
            this.myRemoveModal.hide();            
        }
        if (error) {
            console.log(error);
        }
      },
  },
  mounted() {
    this.getData();
  },
  data() {
    return {
      myAddModal: null,
      myRemoveModal: null,
      exercisename: '',
      access: '',
      type: '',
      status: '',
      aliases: '',
      aliasarray: Array,
      exerciseid: '',
      removeid: '',
      exercise: '',
      exercises: Array,
      title: "Exercises",
      items: [
        {
          text: "Admin",
          href: "/",
        },
        {
          text: "Exercises",
          active: true,
        },
      ],
    };
  },
  components: {
    Layout,
    PageHeader,
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
     <!-- start page title -->
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title mb-0">{{ $t("t-Exercisestableheading") }}</h4>
                </div><!-- end card header -->

                <div class="card-body">
                    <div id="customerList">
                        <div class="row g-4 mb-3">
                            <div class="col-sm-auto">
                                <div>
                                    <button type="button" class="btn btn-info add-btn" @click="$router.push('/exercise/add')" id="create-btn"><i class="ri-add-line align-bottom me-1"></i> {{ $t("t-Add") }}</button>
                                </div>
                            </div>
                            <div class="col-sm">
                                <div class="d-flex justify-content-sm-end">
                                    <div class="search-box ms-2">
                                        <input type="text" class="form-control search" placeholder="Search...">
                                        <i class="ri-search-line search-icon"></i>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="table-responsive table-card mt-3 mb-1">
                           
                            <table class="table align-middle table-nowrap" id="customerTable">
                                <thead class="table-light">
                                    <tr>
                                        <th scope="col" style="width: 50px;">
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" id="checkAll" value="option">
                                            </div>
                                        </th>
                                        <th class="sort" data-sort="id">{{ $t("t-Id") }}</th>
                                        <th class="sort" data-sort="name">{{ $t("t-Name") }}</th>
                                        <th class="sort" data-sort="access">{{ $t("t-Access") }}</th>
                                        <th class="sort" data-sort="type">{{ $t("t-Type") }}</th>
                                        <th class="sort" data-sort="status">{{ $t("t-Status") }}</th>
                                        <th class="sort" data-sort="created">{{ $t("t-CreatedAt") }}</th>
                                        <th class="sort" data-sort="action">{{ $t("t-Action") }}</th>
                                    </tr>
                                </thead>
                                <tbody class="list form-check-all">
                                    <tr scope="row" v-for="(exercise, index) in exercises.result" :key="index">
                                        <th scope="row">
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" name="chk_child" value="option1">
                                            </div>
                                        </th>
                                        <td>{{exercise._id}}</td>
                                        <td>{{exercise.name}}</td>
                                        <td>{{exercise.access}}</td>
                                        <td>{{exercise.type}}</td>
                                        <td>{{exercise.status}}</td>
                                        <td>{{exercise.createdAt}}</td>
                                        <td>
                                            <div class="d-flex gap-2">
                                                <div class="aliases">
                                                    <button class="btn btn-sm btn-info alias-item-btn" @click="aliasModal(exercise, index)">{{ $t("t-Alias") }}</button>
                                                </div>
                                                <div class="remove">
                                                    <button class="btn btn-sm btn-danger remove-item-btn" @click="deleteModal(exercise, index)">{{ $t("t-Remove") }}</button>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="noresult" style="display: none">
                                <div class="text-center">
                                    <h5 class="mt-2">{{ $t("t-SorryNoresultsfound") }}</h5>
                                    <p class="text-muted mb-0">{{ $t("t-Wecouldntfindanyting") }}</p>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex justify-content-end">
                            <div class="pagination-wrap hstack gap-2">
                                <a class="page-item pagination-prev disabled" href="#">
                                    {{ $t("t-Previous") }}
                                </a>
                                <ul class="pagination listjs-pagination mb-0"></ul>
                                <a class="page-item pagination-next" href="#">
                                    {{ $t("t-Next") }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div><!-- end card -->
            </div>
            <!-- end col -->
        </div>
        <!-- end col -->
    </div>
    <!-- end row -->
    <!-- Modal -->
    <div class="modal fade zoomIn deleteModal" id="deleteModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="btn-close"></button>
                </div>
                <div class="modal-body">
                    <div class="mt-2 text-center">
                        <input type="hidden" ref="exerciseid" id="exerciseid" :value="exercise._id"/>
                        <div class="mt-4 pt-2 fs-15 mx-4 mx-sm-6">
                            <h4>{{ $t("t-AreYouSure") }}</h4>
                            <p class="text-muted mx-4 mb-0">{{ $t("t-Areyousureyouwanttoremovethisexercise") }}</p>
                            <p>{{exercise.name}}</p>
                        </div>
                    </div>
                    <div class="d-flex gap-2 justify-content-center mt-4 mb-2">
                        <button type="button" class="btn w-sm btn-light" data-bs-dismiss="modal">{{ $t("t-Close") }}</button>
                        <button type="submit" class="btn w-sm btn-danger " @click.prevent="removeExercise(exercise,removeid)" id="add-record">{{ $t("t-Add") }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--end modal -->
    <!-- Modal -->
    <div class="modal fade zoomIn aliasModal" id="aliasModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="btn-close"></button>
                </div>
                <div class="modal-body">
                    <div class="mt-2">
                        <input type="hidden" ref="exerciseid" id="exerciseid" :value="exercise._id"/>
                        <div class="row">
                            <div class="col-12">
                                <div>
                                    <p>Add aliases below. You can add more than one alias, by seperating them with comma.</p>
                                    <input type="text" ref="aliases" class="form-control" id="aliases" placeholder="Add aliases">
                                </div>
                            </div>
                            <!--end col-->
                        </div>
                        <!--end row-->
                    </div>
                    <div class="d-flex gap-2 justify-content-center mt-4 mb-2">
                        <button type="button" class="btn w-sm btn-light" data-bs-dismiss="modal">{{ $t("t-Close") }}</button>
                        <button type="submit" class="btn w-sm btn-danger " @click.prevent="addAliases()" id="delete-record">{{ $t("t-Add") }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--end modal -->
    </Layout>
</template>